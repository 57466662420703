import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class ReportsService {
    
    constructor(
        private http: HttpClient,
        private authenticationService: AuthenticationService
    ) { }

    getSoaStatmentsList(emailId: string, pan: string, fundName: string, folio: string, fileType: string, year: string, fundDate: string) {
        return this.http.post<any>(this.authenticationService.baseURL+'api/SOA/getSOAList',{
            "fundName": fundName,
            "pan": pan,
            "emailId": emailId,
            "folio": folio,
            "fileType": fileType,
            "year": year,
            "fundDate": fundDate
        });
    }

    getSoaDocumentsList(emailId: string, pan: string, fundName: string, fileType: string, year: string, fundDate: string) {
        return this.http.post<any>(this.authenticationService.baseURL+'api/SOA/getFundwiseSOAList',{
            "fundName": fundName,
            "pan": pan,
            "emailId": emailId,
            "fileType": fileType,
            "year": year,
            "fundDate": fundDate
        });
    }

    getDownloadStreamFile(fileServreUrl: string, fileName: string) {
        return this.http.post<any>(this.authenticationService.baseURL+'api/files/DownloadFile',{
            "FileServerURL": fileServreUrl,
            "FileName": fileName
        },{
            'responseType'  : 'arraybuffer' as 'json' 
        });
    }
}

export class Report {
    folioNo: string
    productName: string
    fileType: string
    year: string
    fileDate: any
    filePath: string
}
