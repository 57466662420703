import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class FaqService {
    
    constructor(
        private http: HttpClient,
        private authenticationService: AuthenticationService
    ) { }

    submitInvestorQuery(emailId: string, name: string, phone: string, body: string, subject: string, fundName: string, folioNo: string) {
        return this.http.post<any>(this.authenticationService.baseURL+'api/investor/submitQuery',{
            "investorName": name,
            "investorEmail": emailId,
            "investorMobile": phone,
            "body": body,
            "subject": subject,
            "fundName": fundName,
            "folioNo": folioNo
        });
    }
}
