import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as Highcharts from 'highcharts';
import { CLientAum, InvestmentOverView } from 'src/app/dashboard/dashboard.component';
import { User } from 'src/app/login/login.component';
import { DashboardService } from 'src/app/services/dashboard.service';
import { StorageService } from 'src/app/services/storage.service';
import { Utils } from 'src/app/services/utils';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  currencyType: string
  user: User = new User
  username: string = ""
  clientAum: CLientAum = new CLientAum
  errorMessage: string = ""
  emailId: string = ""
  investmentOverview: InvestmentOverView[]
  loading: boolean = false;
  active = 1;
  openLeftMenu = false;
  colorPallete = [ '#D6a61C', '#188BEF', '#48BE92', '#4563DF', '#A239A0', '#F16636'];
  todaysDate = new Date();
  transactionList = ""
  multiplePan : boolean = false;

  constructor(
    private storageService: StorageService,
    private utils: Utils,
    public router: Router,    
    private dashboardService: DashboardService,
  ) { }

  ngOnInit(): void {
    const userJson = JSON.parse(this.storageService.getFromStorage('LoggedInUser'))
    this.user = userJson[0]
    let panList = this.storageService.getFromStorage("PanList");
    this.multiplePan = panList.length > 1 ? true : false;
    let userShore = this.user.onshore_Offshore
    this.emailId = this.storageService.getFromStorage('LoggedInEmail')
    this.username = this.utils.getInitials(this.user.investor_Name)
    this.currencyType = 'INR';
    // if (this.storageService.getFromStorage('currencyType') == undefined) {
    //   if (userShore === 'Onshore') {
    //     this.currencyType = 'INR'
    //   } else {
    //     this.currencyType = 'USD'
    //   }
    // } else {
    //   this.currencyType = this.storageService.getFromStorage('currencyType')
    // }
    this.getClientAum()
    this.getInvestmentOverview()
    this.setCurrencyType()    
    this.getTransactionManualType()
  }

  setCurrencyType() {
    this.storageService.setSessionStorage('currencyType', this.currencyType);
  }

  logout() {
    sessionStorage.clear()
    this.router.navigateByUrl('/login')
  }

  getTransactionManualType(){
    this.dashboardService.getTransactionManualType( this.user.paN_No, this.user.onshore_Offshore).subscribe(item=> {
        item.value.forEach(element => {
          this.transactionList += "<li>"+element.transactionType+'</li>';
        });
        // console.log(this.transactionList)
    })
  }

  getClientAum() {
    // let currencyType = this.storageService.getFromStorage('currencyType')    
    this.loading = true;
    this.dashboardService.getClientAum(this.emailId, this.user.paN_No, this.currencyType, this.user.onshore_Offshore).subscribe(item => {
      this.loading = false;
      if (item.code === 200 && item.value.length && item.value.length > 0) {
        this.clientAum = item.value[0];
        this.clientAum.balance_Committed = this.utils.convert2DigitDecimal(this.clientAum.balance_Committed);
        this.clientAum.capitalCommitment = this.utils.convert2DigitDecimal(this.clientAum.capitalCommitment);
        this.clientAum.capital_Distributed = this.utils.convert2DigitDecimal(this.clientAum.capital_Distributed);
        this.clientAum.contribution_Amount = this.utils.convert2DigitDecimal(this.clientAum.contribution_Amount);
        // console.log(this.clientAum)
      } else {
        this.errorMessage = item.erorMsg
      }
    }, error => {
      this.loading = false;
    })
  }

  goToPan(){
    this.router.navigateByUrl('/pan-check')
  }

  getInvestmentOverview() {
    let colors = [];
    // let currencyType = this.storageService.getFromStorage('currencyType')    
    this.loading = true;
    this.dashboardService.getInvestmentOverview(this.emailId, this.user.paN_No, this.currencyType, this.user.onshore_Offshore).subscribe(item => {
      this.loading = false
      if (item.code === 200 && item.value.length && item.value.length > 0) {
        this.investmentOverview = item.value
        this.investmentOverview.sort((a,b) => a.l3.localeCompare(b.l3));
        let capitalInvested: any[] = []
        this.investmentOverview.forEach((element, index) => {
          element.amount= this.utils.convert2DigitDecimal(element.amount);
          element.capitalInvested = this.utils.convert1DigitDecimal(element.capitalInvested);
          capitalInvested.push([element.l3, Number(element.capitalInvested), Number(element.amount)])
        });
        colors.push(this.colorPallete[0]);
        for(let i=1;i<this.investmentOverview.length;i++){
          colors.push(this.colorPallete[i]);
        }
        // console.log(colors)
        let donutChartOptions: any = {
          chart: {
            type: 'variablepie',
            plotBackgroundColor: null,
            plotBorderWidth: 0,
            plotShadow: false,
            backgroundColor: null,
            height: '250'
          },
          title: {
            text: '',
            align: 'center',
            verticalAlign: 'middle',
            y: 60
          },
          credits: { enabled: false },
          exporting: { enabled: false },
          tooltip: { pointFormat: 'Commitment %: <b>{point.y}%</b><br> Commitment Amount: '+(this.currencyType=='INR' ? '<b>{point.z} Cr</b>' : '<b>{point.z} Mn </b>')},
          accessibility: {
            point: { valueSuffix: '%' }
          },
          plotOptions: {
            variablepie: {
              innerSize: 100,
              depth: 45,
              dataLabels: {
                enabled: false,
              },
              borderWidth: 0
            }
          },
          colors: colors,
          series: [{
            type: 'variablepie',
            name: 'Browser share',
            zMin: 0,
            innerSize: '50%',
            data: capitalInvested
          }]
        };
        Highcharts.chart('donutChart', donutChartOptions)
      } else {
        this.errorMessage = item.erorMsg
      }
    }, error=>{
      this.loading = false;
    })
  }

  changeCurrencyType() {
    this.setCurrencyType();
    location.reload();
  }

}
