import { Component, OnInit } from '@angular/core';
import {Event, NavigationEnd, Router} from '@angular/router';
import { BnNgIdleService } from 'bn-ng-idle';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'pd-virat';
  route:String;
  
  constructor(
    private router : Router,
    private bnIdle: BnNgIdleService
  ){
    
  }

  ngOnInit(){
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd ) {
        this.route = event.url;
      }
    });
    this.bnIdle.startWatching(900).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        //logout on timeOut
        sessionStorage.clear()
        this.router.navigateByUrl('/login')        
      }
    });
  }
}
