<div *ngIf="loading" class="spinner-border loader" role="status">
    <span class="sr-only">Loading...</span>
</div>
<div class="{{loading ? 'overlay' : ''}}"></div>
<div class="blue_box">
    <div class="header">
        <div class="container-fluid">
            <button type="button" id="menuButton" class="btn menu_icon back_link" (click)="openLeftMenu=!openLeftMenu"><img
                class="" src="assets/img/menu_icon.svg" alt=""></button>
            <a href="/dashboard" class="logo"><img src="../../../assets/img/edelweiss_logo.png" alt=""></a>

            <ul class="top_right">
                <li class="user">
                    <ng-template #tipContent>
                        <table>
                            <tr>
                                <td *ngIf="multiplePan" class="next-line"><div type="button" (click)="goToPan()">Switch PAN</div></td>
                                <hr *ngIf="multiplePan" class="line1">
                                <td class="next-line"><div type="button" (click)="logout()">Logout</div></td>
                            </tr>
                        </table>
                    </ng-template>
                    <div type="button" class="user_name" placement="bottom" 
                        [ngbTooltip]="tipContent" 
                        triggers="click"
                        [autoClose]="'outside'"><i class="fas fa-power-off"></i>
                    </div>
                </li>
                <!-- <li class="bell"><a><img src="assets/img/notifications.svg" alt=""></a></li>
                <li class="currency_drop">
                    <select class="form-control" [(ngModel)]="currencyType" (change)='changeCurrencyType()'>
                        <option value="USD" selected>$ USD (in mn)</option>
                        <option value="INR">&#8377; INR (in cr)</option>
                    </select>

                    <select class="form-control mob_drop">
                        <option>$</option>
                        <option>&#8377;</option>
                    </select>
                </li> -->
                <li class="top_link"><a class="hover" href="/faq">Support</a></li>  
                <li class="top_link"><a class="hover" href="/qa">FAQs</a></li>                 
                <li class="top_link"><a class="hover" href="/reports">Downloads</a></li>
                <!-- <li class="top_link"><a class="hover"
                        href="/investment-summary">Reports</a></li> -->
                <li class="top_link"><a class="hover" href="/dashboard">Home</a>
                </li>

            </ul>


            <div *ngIf="openLeftMenu" id="leftmenu" class="leftmenu_data">
                <a class="menu_close" (click)="openLeftMenu=false"><img
                    src="assets/img/close.png" alt=""></a>
                <ul class="left_link">
                    <li><a href="/dashboard">Home</a></li>
                    <!-- <li><a href="/investment-summary">Reports</a></li> -->
                    <li><a href="/reports">Downloads</a></li>
                    <li><a href="/qa">FAQs</a></li>  
                    <li><a href="/faq">Support</a></li> 
                    <li><a href="/login">Logout</a></li>
                </ul>
            </div>

        </div>
    </div>
    <div *ngIf="router.url==='/faq'" class="container-fluid">
        <div class="back_link"><a href="/dashboard"><img src="assets/img/link_back.svg" alt=""> Support</a></div>
    </div>
    <div *ngIf="router.url==='/investment-summary'" class="container-fluid">
        <div class="back_link"><a href="/dashboard"><img src="assets/img/link_back.svg" alt=""> Cashflow Summary</a></div>
    </div>
    <div *ngIf="router.url==='/reports'" class="container-fluid">
        <div class="back_link"><a href="/dashboard"><img src="assets/img/link_back.svg" alt=""> Downloads</a></div>
    </div>
    <div *ngIf="router.url==='/qa'" class="container-fluid">
        <div class="back_link"><a href="/dashboard"><img src="assets/img/link_back.svg" alt=""> FAQs</a></div>
    </div>
    <div [hidden]="router.url!=='/dashboard'" class="container-fluid mob_mr">
        <hr class="line">

        <div class="row">
            <div class="col-md-6">
                <h1 *ngIf="currencyType=='INR'">
                    <span>Total commitment (As on {{todaysDate | date:'MMM dd, YYYY'}}) </span><br>&#8377;{{clientAum.capitalCommitment}} cr
                    <a class="download_icon mob_download"><img src="assets/img/download_icon.svg" alt=""></a>
                </h1>
                <h1 *ngIf="!(currencyType=='INR')">
                    <span>Total commitment (As on {{todaysDate | date:'MMM dd, YYYY'}}) </span><br>${{clientAum.capitalCommitment}} mn
                    <a class="download_icon mob_download"><img src="assets/img/download_icon.svg" alt=""></a>
                </h1>

                <div class="investment_box">
                    <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-6 box4">
                            <div class="box4_txt">
                                <img src="assets/img/icon1.svg" alt="">
                                <h2 *ngIf="currencyType=='INR'">
                                    <span>Gross drawn capital</span><br>
                                    &#8377;{{clientAum.contribution_Amount}} cr
                                </h2>
                                <h2 *ngIf="!(currencyType=='INR')">
                                    <span>Gross drawn capital</span><br>
                                    ${{clientAum.contribution_Amount}} mn
                                </h2>
                            </div>
                        </div>

                        <div class="col-md-6 col-sm-6 col-xs-6 box4">
                            <div class="box4_txt">
                                <img src="assets/img/icon2.svg" alt="">
                                <h2 *ngIf="currencyType=='INR'"><span>Balance
                                        commitment</span><br>&#8377;{{clientAum.balance_Committed}} cr</h2>
                                <h2 *ngIf="!(currencyType=='INR')"><span>Balance
                                        commitment</span><br>${{clientAum.balance_Committed}} mn</h2>
                            </div>
                        </div>

                        <div class="col-md-6 col-sm-6 col-xs-6 box4">
                            <div class="box4_txt">
                                <img src="assets/img/icon3.svg" alt="">
                                <ng-template #popContent><div style="text-align: left;" [innerHTML]="transactionList"></div></ng-template>
                                <h2 *ngIf="currencyType=='INR'"><span>Capital redeemed
                                    <!-- <span style="cursor: pointer;" [ngbPopover]="popContent" placement="right" triggers="mouseenter:mouseleave">*</span> -->
                                </span><br>&#8377;{{clientAum.capital_Distributed}} cr</h2>
                                <h2 *ngIf="!(currencyType=='INR')"><span>Capital redeemed
                                    <!-- <span style="cursor: pointer;" [ngbPopover]="popContent" placement="right" triggers="mouseenter:mouseleave">*</span> -->
                                </span><br>${{clientAum.capital_Distributed}} mn</h2>
                            </div>
                        </div>
                        
                        <div class="col-md-6 col-sm-6 col-xs-6 box4">
                            <div class="box4_txt">
                                <img src="assets/img/icon4.svg" alt="">
                                <h2 *ngIf="currencyType=='INR'"><span>Net drawn capital</span><br>&#8377;{{clientAum.netCapitalInvested}} cr</h2>
                                <h2 *ngIf="!(currencyType=='INR')"><span>Net drawn capital</span><br>${{clientAum.netCapitalInvested}} mn</h2>
                            </div>
                        </div>
                        <!-- <div class="col-md-6 col-sm-6 col-xs-6 box4">
                            <div class="box4_txt">
                                <img src="assets/img/icon4.svg" alt="">
                                <h2><span>Investor Level IRR</span><br>{{clientAum.irr}}%</h2>
                            </div>
                        </div> -->
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>


            <div class="col-md-6">
                <div class="title_pie">
                    <strong>Commitment breakup</strong>
                    <!-- <a href="/reports" class="download_icon"><img src="assets/img/download_icon.svg" alt=""></a> -->
                    <!-- <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav nav-tabs custom_tab">
                        <li [ngbNavItem]="1" class="active" id="test1">
                            <a ngbNavLink>test1</a>
                        </li>
                        <li [ngbNavItem]="2" id="test2">
                            <a ngbNavLink>test2</a>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav"></div> -->
                    <div class="clearfix"></div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-xs-6 pie_chart">
                        <div id="donutChart" style="overflow: visible !important;"></div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-6 pie_legent">
                        <div *ngFor=" let inv of investmentOverview; index as i" class="legent_value">
                            <img src="assets/img/legend{{i+1}}.svg" alt="">
                            <h2><span>{{inv.l3}}</span>{{inv.capitalInvested}}%</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>