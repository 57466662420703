import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BnNgIdleService } from 'bn-ng-idle';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationService } from './services/authentication.service';
import { JwtInterceptor } from './services/jwt.interceptor';
import { LocalSettingsService } from './services/local-setting.service';
import { LoginService } from './services/login.service';
import { StorageService } from './services/storage.service';
import { TokenInterceptor } from './services/token.interceptor';
import { HeaderComponent } from './layout/header/header.component';
import { DashboardService } from './services/dashboard.service';
import { FormsModule } from '@angular/forms';
import { Utils } from './services/utils';
import { NgbDropdownModule, NgbNavModule, NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ReportsService } from './services/reports.service';
import { DatePipe } from '@angular/common';
import { FaqService } from './services/faq.service';
import { NgxCaptchaModule } from 'ngx-captcha';
import { HeaderEmptyComponent } from './layout/header-empty/header-empty.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HeaderEmptyComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    NgxCaptchaModule,
    NgbNavModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbPopoverModule,
    AppRoutingModule 
  ],
  providers: [
    LoginService,
    LocalSettingsService,
    StorageService,
    DashboardService,
    AuthenticationService,
    ReportsService,
    FaqService,
    DatePipe,
    Utils,
    BnNgIdleService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
