import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Chart } from 'angular-highcharts'
import * as Highcharts from 'highcharts';
import highcharts3D from 'highcharts/highcharts-3d';
highcharts3D(Highcharts);
import Variablepie from "highcharts/modules/variable-pie";
import { DrawdownAndDistribution } from '../investment-summary/investment-summary.component';
Variablepie(Highcharts);
import { User } from '../login/login.component';
import { DashboardService } from '../services/dashboard.service';
import { StorageService } from '../services/storage.service';
import { Utils } from '../services/utils';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    lineChart: Chart;
    emptyLineChart: boolean = false;
    fundDetails: FundDetails[] = []
    openFilterModal: boolean = false
    user: User = null
    errorMessage: string = ""
    emailId: string = ""
    closeResult: string
    active=2
    fundActive = 0
    currencyType
    bussinessType: string[] = []
    fundids: string[] = []
    filterFundName: string = ""
    filterBusinessType: string = ""
    activeFilterModal = 0
    filterDateRange = ""
    drawdownAndDistribtion: DrawdownAndDistribution[] = [];
    drawAndDist: DrawdownAndDistribution[] = [];
    route: string
    loadingfundDetails = false

    constructor(
        private dashboardService: DashboardService,
        private storageService: StorageService,
        private modalService: NgbModal,
        private router: Router,
        private utils: Utils,        
        private datePipe: DatePipe
    ) { }

    ngOnInit(): void {
        let userJson = JSON.parse(this.storageService.getFromStorage('LoggedInUser'))
        this.user = userJson[0]
        this.emailId = this.storageService.getFromStorage('LoggedInEmail')
        this.currencyType = this.storageService.getFromStorage('currencyType')
        // console.log(this.user)
        this.getFundDetails()
        this.getDrawndownAndDistribution()
    }

    getFundDetails() {
        let currencyType = this.storageService.getFromStorage('currencyType')
        let fundNamesStr = ""
        this.loadingfundDetails = true;
        this.dashboardService.getFundDetails(this.emailId, this.user.paN_No, currencyType, this.user.onshore_Offshore).subscribe(item => {
            // console.log(item)
            this.loadingfundDetails = false;  
            if (item.code === 200 && item.value.length && item.value.length > 0) {
                this.fundDetails = item.value;
                // console.log(this.fundDetails)
                this.fundDetails.forEach(element => {
                    fundNamesStr += element.fundName+",";
                    element.finalClosingDate = this.utils.convertDateTime(element.finalClosingDate)
                    element.commitmentPeriod = this.utils.convertDateTime(element.commitmentPeriod)
                    element.investorNetIRR1 = this.utils.convert2DigitDecimal(element.investorNetIRR1)
                    element.aum = (element.aum)
                    element.amount = this.utils.convert2DigitDecimal(element.amount);
                    element.grossIRR = this.utils.convert2DigitDecimal(element.grossIRR)
                    element.netIRR = this.utils.convert2DigitDecimal(element.netIRR)
                    element.nav = element.nav;
                    element.drawdown = this.utils.convert2DigitDecimal(element.drawdown);                    
                    element.distribution = this.utils.convert2DigitDecimal(element.distribution);
                    element.netCapitalInvested = this.utils.convert2DigitDecimal(element.netCapitalInvested);
                    this.dashboardService.getFundDetailsStackedBar(element.fundName,this.user.onshore_Offshore).subscribe( item => {         
                    // this.dashboardService.getFundDetailsStackedBar("EIYP","OnShore").subscribe( item => {
                        if(item.code == 200){
                            // console.log(item)
                            let data : any[] = [];
                            let stackedBarDetails = item.value;
                            // console.log(stackedBarDetails)
                            stackedBarDetails.forEach(el => {
                                let dataObj : SeriesData = new SeriesData;
                                dataObj.name = el.deal_Sector;
                                dataObj.type = undefined
                                dataObj.data = [el.investment_Amount];
                                data.push(dataObj);
                            });
                            if(data.length > 0){
                                element.graph = new Chart({
                                    chart: {
                                        type: 'bar'
                                    },
                                    title: { text: '' },
                                    tooltip: {
                                        pointFormat: '{series.name}: <b>{point.percentage:.2f}%</b><span class="tooltip-pointer"></span>',
                                        // shape: 'square',
                                        // positioner: function (labelWidth, labelHeight, point) {
                                        //     return {
                                        //         x: (point.plotX - labelWidth/2)/2,
                                        //         y: point.plotY - labelHeight -2
                                        //     };
                                        // }
                                    },
                                    xAxis: { categories: [''], visible: false, endOnTick: false, },
                                    yAxis: {
                                        endOnTick: false,
                                        visible: false,
                                        labels: { enabled: false },
                                        min: 0,
                                        title: { text: '' }
                                    },
                                    credits: { enabled: false },
                                    exporting: { enabled: false },
                                    legend: { reversed: true },
                                    plotOptions: {
                                        series: { stacking: 'normal' },
                                        bar: {
                                            groupPadding: 0.10,
                                            pointWidth: 20,
                                            pointPadding: 0,
                                        }
                                    },
                                    colors: ['#FFEC38', '#FFAB00', '#F37736', '#EE4035', '#8F0031', '#A239A0'],
                                    series: data
                                }); 
                            }
                        }   
                        // console.log(element)              
                    });                   
                });                     
                this.fundDetails.sort((a,b) => a.fundName.localeCompare(b.fundName));
            } else {
                // console.log(item.errorMsg)
                this.errorMessage = item.errorMsg
            }
            this.storageService.setSessionStorage("allFundNames",fundNamesStr);              
        },error =>{
            this.loadingfundDetails = false;
        });
    }

    getDrawndownAndDistribution() {
        let currencyType = this.storageService.getFromStorage('currencyType')
        this.dashboardService.getInvestmentDrawdownAndDistribution(this.emailId, this.user.paN_No, currencyType, this.user.onshore_Offshore).subscribe(item => {
            if (item.code === 200 && item.value.length && item.value.length > 0) {
                let drawdownAndDistribtion = item.value.sort((a,b) => a.manual_DueDate.localeCompare(b.manual_DueDate));          
                // console.log(drawdownAndDistribtion)
                drawdownAndDistribtion.forEach(element => {
                if(this.drawdownAndDistribtion.length == 0){
                    this.drawdownAndDistribtion.push(element);
                }else{                    
                    let lastElement = this.drawdownAndDistribtion[this.drawdownAndDistribtion.length-1];
                    if((this.datePipe.transform(lastElement.manual_DueDate, 'LLL, y') == this.datePipe.transform(element.manual_DueDate, 'LLL, y')) && lastElement.fundid == element.fundid){
                        if((lastElement.drawdown!="0.00") && (element.drawdown!="0.00")){
                            lastElement.drawdown = lastElement.drawdown+ element.drawdown;
                            lastElement.drawdownpercentage = lastElement.drawdownpercentage + element.drawdownpercentage;                                
                        } else if((lastElement.distribution!="0.00") && (element.distribution!="0.00")){
                            lastElement.distribution = lastElement.distribution+ element.distribution;
                            lastElement.drawdownpercentage = lastElement.drawdownpercentage + element.drawdownpercentage;
                        } else {
                            this.drawdownAndDistribtion.push(element);
                        }                 
                    }else {
                        this.drawdownAndDistribtion.push(element);
                    }
                }
                });
                this.drawdownAndDistribtion.forEach(element => {
                    if (this.bussinessType.indexOf(element.businessType) == -1) {
                        this.bussinessType.push(element.businessType)
                    }
                    if (this.fundids.indexOf(element.fundid) == -1) {
                        this.fundids.push(element.fundid)
                    }
                    element.drawdownpercentage = this.utils.convert2DigitDecimal(element.drawdownpercentage)
                    element.drawdown = this.utils.convert2DigitDecimal(element.drawdown)
                    element.distribution = this.utils.convert2DigitDecimal(element.distribution)
                });
                this.lineGraphAsPerAppliedFilter()
            }
        });
    }


    lineGraphAsPerAppliedFilter() {
        this.modalService.dismissAll()
        let drawdown = []
        let distribution = []
        let drawAndDist = []
        this.drawdownAndDistribtion.forEach(element => {            
            let eleDateTime = new Date(element.manual_DueDate).getTime();
            if(!this.filterFundName){
                if(!this.filterBusinessType){
                    this.getDataForLineChart(element, drawdown, distribution,eleDateTime,drawAndDist)   
                } else{
                    if(element.businessType === this.filterBusinessType){
                        this.getDataForLineChart(element, drawdown, distribution,eleDateTime,drawAndDist)
                    }
                }          
            } else {
                if(!this.filterBusinessType){
                    if(element.fundid === this.filterFundName){
                        this.getDataForLineChart(element, drawdown, distribution,eleDateTime,drawAndDist)             
                    }
                } else{
                    if((element.businessType === this.filterBusinessType) && (element.fundid === this.filterFundName)){
                        this.getDataForLineChart(element, drawdown, distribution,eleDateTime,drawAndDist)
                    }
                }
            }
        });
        this.drawAndDist = drawAndDist
        if(!(drawdown.length ==0) || !(distribution.length ==0)){
            this.emptyLineChart = false
            this.lineChart = new Chart({
                chart: {
                    type: 'spline'
                },
                title: { text: '' },
                subtitle: { text: '' },
                credits: { enabled: false },
                exporting: { enabled: false },
                yAxis: {
                    title: { text: 'Amount' },
                    labels: {
                        format: (this.currencyType=='INR' ? '{value} Cr' : '{value} Mn')
                    },
                },
                xAxis: {
                    type: 'datetime',
                    tickInterval: (this.filterDateRange=="3" ? 12*3600*1000*365 : 24*3600*1000*365),
                    startOnTick: true             
                },

                legend: {
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'bottom'
                },
                tooltip: {
                    headerFormat: '{point.x: %e.%b.%Y}<br>',
                    pointFormat: '{series.name} : <b>{point.y}'+(this.currencyType=='INR' ? '{value} Cr' : '{value} Mn')+'</b>'
                },
                plotOptions: {
                    series: {                    
                        marker: {
                            enabled: true
                        },
                        label: {
                            connectorAllowed: false
                        },
                    }
                },
                colors: ['#FFE380', '#F37736'],

                series: [{
                    name: 'Drawdown',
                    type: 'line',
                    data: drawdown
                }, {
                    name: 'Distribution',
                    type: 'line',
                    data: distribution
                }],

                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 500
                        },
                        chartOptions: {
                            legend: {
                                layout: 'horizontal',
                                align: 'center',
                                verticalAlign: 'bottom'
                            }
                        }
                    }]
                }
            });   
        } else {
            this.lineChart = null
            this.emptyLineChart = true
        }    

    }

    getDataForLineChart(element, drawdown, distribution,eleDateTime, drawAndDist){
        switch(this.activeFilterModal){
            case 0 :
                this.getByDateRange(drawdown,distribution,eleDateTime,element,drawAndDist, 0)
                break;
            case 1 :
                this.getByDateRange(drawdown,[],eleDateTime,element,drawAndDist, 1)
                break;
            case 2 : 
                this.getByDateRange([],distribution,eleDateTime,element,drawAndDist, 2)
                break;
        }               
    }

    getDateByDiff(diff){
        let pastDiffYear = new Date().getFullYear()-diff
        let pastDiff = new Date()
        pastDiff.setFullYear(pastDiffYear)
        return pastDiff
    }

    getByDateRange(drawdown,distribution,eleDateTime,element, drawAndDist, caseNo){
        switch(this.filterDateRange){
            case "":          
                if(element.drawdown!=0){         
                    if(caseNo ==1)               
                        drawAndDist.push(element)   
                    drawdown.push([eleDateTime,element.drawdown])
                }
                if(element.distribution!=0 ){    
                    if(caseNo ==2)                
                        drawAndDist.push(element)     
                    distribution.push([eleDateTime,element.distribution])
                } 
                if(caseNo == 0){
                    drawAndDist.push(element)
                }
                break;
            case "3":
                let past3 = this.getDateByDiff(3);
                if(past3.getTime() < eleDateTime){
                    if(element.drawdown!=0 ){   
                        if(caseNo ==1)  
                            drawAndDist.push(element)
                        drawdown.push([eleDateTime,element.drawdown])
                    }
                    if(element.distribution!=0){  
                        if(caseNo ==2)       
                            drawAndDist.push(element)
                        distribution.push([eleDateTime,element.distribution])
                    }
                    if(caseNo == 0){
                        drawAndDist.push(element)
                    }
                }
                break;
            case "5":                     
                let past5 = this.getDateByDiff(5);
                if(past5.getTime() < eleDateTime){
                    if(element.drawdown!=0){ 
                        if(caseNo ==1)  
                            drawAndDist.push(element)
                        drawdown.push([eleDateTime,element.drawdown])
                    }
                    if(element.distribution!=0){  
                        if(caseNo ==2)  
                            drawAndDist.push(element)   
                        distribution.push([eleDateTime,element.distribution])
                    }
                    if(caseNo == 0){
                        drawAndDist.push(element)
                    }
                }
                break;                        
        }
    }

    open(filter) {
        this.modalService.open(filter, { ariaLabelledBy: 'modal-basic-title', windowClass: 'custom_pop' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
            // console.log(result);
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
}

export class FundDetails {
    fundid: string
    fundName: string
    // fundShortName: string
    finalClosingDate: string
    commitmentPeriod: string
    termPeriod: any
    grossIRR: any
    netIRR: any
    businessType: string
    aum: any
    amount: any
    currency: string
    graph: any
    displayFullName: string
    investorNetIRR1: any
    nav: any
    nav_Date: any
    netCapitalInvested: any
    drawdown: any;
    distribution: any;
}

export class CLientAum {
    contribution_Amount: string
    balance_Committed: string
    capital_Distributed: string
    capitalCommitment: string
    irr: string
}

export class InvestmentOverView {
    l3: string
    capitalInvested: string
    amount: any
}

export class SeriesData {
    data: any
    name: string
    type : string
}
