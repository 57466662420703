import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class DashboardService {
    
    constructor(
        private http: HttpClient,
        private authenticationService: AuthenticationService
    ) { }


    getFundDetails(emailId: string, pan: string, currency: string, shoretype: string ){
        return this.http.post<any>(this.authenticationService.baseURL+'api/product/details',{
            "currency": currency,
            "pan": pan,
            "emailId": emailId,
            "shoretype": shoretype
        });
    }

    getClientAum(emailId: string, pan: string, currency: string, shoretype: string){
        return this.http.post<any>(this.authenticationService.baseURL+'api/product/clientaum',{
            "currency": currency,
            "pan": pan,
            "emailId": emailId,
            "shoretype": shoretype
        });
    }

    getInvestmentOverview(emailId: string, pan: string, currency: string, shoretype: string){
        return this.http.post<any>(this.authenticationService.baseURL+'api/product/investmentOverview',{
            "currency": currency,
            "pan": pan,
            "emailId": emailId,
            "shoretype": shoretype
        });
    }

    getInvestmentDrawdownAndDistribution(emailId: string, pan: string, currency: string, shoretype: string){
        return this.http.post<any>(this.authenticationService.baseURL+'api/product/getCashflowDrawdownandDistribution',{
            "currency": currency,
            "pan": pan,
            "emailId": emailId,
            "shoretype": shoretype
        });
    }

    getFundDetailsStackedBar(fundName:string, shoretype: string ){
        return this.http.post<any>(this.authenticationService.baseURL+'api/Fund/getFundDetails',{
            "fundName": fundName,
            "shoretype": shoretype
        });
    }

    getTransactionManualType(pan:string, shoretype: string ){
        return this.http.post<any>(this.authenticationService.baseURL+'api/product/getTransactionManualType',{
            "pan": pan,
            "shoretype": shoretype
        });
    }

}