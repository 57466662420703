import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {

    constructor() { }

    public setLocalStorage(key: string, value: any): void {
        localStorage.setItem(key, JSON.stringify(value));
    }

    public setSessionStorage(key: string, value: any): void {
        sessionStorage.setItem(key, JSON.stringify(value));
    }    

    public getFromStorage(key: string) {
        var value = sessionStorage.getItem(key);
        if (!value) {
            // Didn't find it in short-term storage, check long-term
            value = localStorage.getItem(key);
        }
        return value === 'undefined' ? '' : JSON.parse(value)
    }
}
