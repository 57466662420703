import { Injectable } from '@angular/core';

@Injectable()
export class Utils {

    constructor() { }
    
    convertDateTime(date: string){        
        if(date!=null){
            const months = ["Jan", "Feb", "March","Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
            let datetime = new Date(date)
            if(!isNaN(Date.parse(date))){
                let formatted_date = datetime.getDate() + " " + months[datetime.getMonth()] + ", " + datetime.getFullYear()
                return formatted_date
            }
        }
        return null
    }

    convertDateTimeForGraph(date: string){
        let datetime = new Date(date)
        let month = datetime.getMonth()+1;
        let formatted_date = month + "-" + datetime.getDate() + "-" + datetime.getFullYear()
        return formatted_date
    }    

    convertDateTimeForReport(date: string){
        let dateParts = date.split('-');
        return new Date(Number(dateParts[2]), Number(dateParts[1])-1, Number(dateParts[0]));  
    }    


    getTimeStamp(date){
        let time = new Date(date);
        return time;
    }

    getInitials (name: string) {        
        let names = name.split(' ')
        let initials = names[0].substring(0, 1).toUpperCase();
        
        // if (names.length > 1) {
        //     initials += names[names.length - 1].substring(0, 1).toUpperCase();
        // }
        return initials;
    }

    getUniqueArrayElements(arr){
        let unique = arr.filter((v, i, a) => a.indexOf(v) === i)
        return unique;
    }

    convert2DigitDecimal(value: any){
        return parseFloat(value).toFixed(2);
    }

    convert1DigitDecimal(value: any){
        return parseFloat(value).toFixed(1);
    }
}
