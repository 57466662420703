import { Injectable } from '@angular/core';

@Injectable()
export class LocalSettingsService {

    // service to maintain filters in Local Storage

    constructor() { }

    public handleTokenStorageForLoggedInUser(token: string, rememberMe: boolean): void {
        if (rememberMe) {
            localStorage.setItem('token', token);
        } else {
            sessionStorage.setItem('token', token);
        }
    }

    public getTokenFromStorage(): string {
        var token = sessionStorage.getItem('token');
        if (!token) {
            // Didn't find it in short-term storage, check long-term
            token = localStorage.getItem('token');
        }
        return token
    }
}
