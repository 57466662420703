import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private authService: AuthenticationService,
    ) { }

    canActivate(): boolean {
        if(!this.authService.getToken()){
            this.router.navigateByUrl('/login');
        }
        return this.authService.getToken();
    }

}