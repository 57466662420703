import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  {path:"",redirectTo:"login",pathMatch:"full"},
  {path:"login", loadChildren:()=>import("./login/login.module").then(m=>m.LoginModule), },
  {path:"faq", loadChildren:()=>import("./faq/faq.module").then(m=>m.FaqModule), canActivate: [AuthGuard]},
  {path:"dashboard", loadChildren:()=>import("./dashboard/dashboard.module").then(m=>m.DashboardModule), canActivate: [AuthGuard]},
  {path:"investment-summary", loadChildren:()=>import("./investment-summary/investment-summary.module").then(m=>m.InvestmentSummaryModule), canActivate: [AuthGuard]},
  {path:"reports", loadChildren:()=>import("./reports/reports.module").then(m=>m.ReportsModule), canActivate: [AuthGuard]},
  {path:"qa", loadChildren:()=>import("./faq-1/faq-1.module").then(m=>m.Faq1Module), canActivate: [AuthGuard]},
  {path:"pan-check", loadChildren:()=>import("./pan-check/pan-check.module").then(m=>m.PanCheckModule), canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }