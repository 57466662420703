import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalSettingsService } from '../services/local-setting.service';
import { LoginService } from '../services/login.service';
import { StorageService } from '../services/storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @ViewChild('login') login: ElementRef

  private siteKey: string;  
  captchaIsLoaded = false;
  captchaSuccess = false;
  captchaIsExpired = false;
  captchaResponse?: string;
  sendingOTP:boolean = false
  verifyingOTP:boolean = false
  emailid: string = ""
  password: string = ""
  errorMessage: string = "" 
  openLogin: boolean = false
  recievedOTP: boolean = false
  emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
  otpPattern = "^[0-9]{6}$"   
  closeResult="";
  timeLeftMessage = "";
  user : any = {};
  panList: any = [];

  constructor(
    private modalService: NgbModal,
    private loginService: LoginService,
    private localSettingService: LocalSettingsService,
    private storageService: StorageService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
    this.siteKey = "6LfOC5obAAAAADXJ3n2MG6Zgjmm6mjKolQElPaeQ"
   }

  ngOnInit(): void {
    sessionStorage.clear()
    this.openLoginModal() 
  }

  openLoginModal(){
    setTimeout(() => {      
      this.open(this)
    })
  }

  sendOTP(userId){
    this.sendingOTP = true
    this.loginService.getOTP(userId).subscribe(item =>{
      let code = item.code;
      if(code == 200){
        this.recievedOTP = true
        this.errorMessage = ""
      } else {
        this.errorMessage = item.errorMsg
      }
      this.sendingOTP = false
    });
  }

  resendOTP(userId){    
    this.sendOTP(userId)
    this.disableButton('resendOTP')
  }

  verifyOTP(userId, otp,modal){    
    this.verifyingOTP = true
    this.loginService.verifyOTP(userId, otp).subscribe(item => {      
      const code = item.code
      if(code == 200){        
        this.verifyingOTP = false;
        modal.close({'reason':'verifyingOTP','emailid':userId, 'password':otp})
        let token = item.value;
        this.emailid = userId;
        // set session token and user here
        this.localSettingService.handleTokenStorageForLoggedInUser(token, false)
        this.getLoggedInUserDetails()
      }else{
        this.errorMessage = item.errorMsg;
        this.verifyingOTP = false;
      }
    })

  }

  getLoggedInUserDetails(){
    this.loginService.getLoggedInUserDetails(this.emailid).subscribe(item => {
        if(item.code === 200){
            this.user = item.value
            this.storageService.setSessionStorage('LoggedInEmail', this.emailid)
            this.storageService.setSessionStorage('LoggedInUser', JSON.stringify(this.user)) // Use JSON.parse to retrieve object
            this.getPans(this.emailid);
        }else{
            this.errorMessage = item.errorMsg
        }
    })
  }

  getPans(emailId : string){
    this.loginService.getPan(emailId).subscribe((item:any)=>{
      // console.log(item);
      item.value.forEach(element => {
        let obj :any = {}
        obj.investorName = element.investor_Name;
        obj.pan = element.pan_No;
        this.panList.push(obj);
      });      
      this.storageService.setSessionStorage('PanList', this.panList)
      // console.log(this.panList);
      if(this.panList.length == 1){
        //navigate to dashboard on success        
        this.router.navigateByUrl('/dashboard')
      }else{
        this.router.navigateByUrl('/pan-check')
      }
    });
  }

  disableButton(btnId){
    const startTime = Date.now();
    const delay = 30000;
    const interval = 1000;
    var btn = <HTMLButtonElement>document.getElementById(btnId); 
    btn.disabled = true;
    const self = this;
    let timeLeft = 30;
    const optTimer = setInterval(function(){
      if(timeLeft >0){
        timeLeft -= 1;
        self.timeLeftMessage="Please wait "+timeLeft+" seconds before resending OTP.";
      }else{
        clearInterval(optTimer);
        btn.disabled = false;
        self.timeLeftMessage = "";
      }
    }, interval);
  }

  open(self) {
    self.modalService.open(self.login, {ariaLabelledBy: 'modal-basic-title', backdropClass:'no-opacity', windowClass: 'custom_pop', beforeDismiss: () => {return false;}}).result.then((result) => {
      self.closeResult = `Closed with: ${result}`;
      if(result.reason === "verifyingOTP"){
      }
    }, (reason) => {
      self.closeResult = `Dismissed ${self.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleLoad(): void {
    this.captchaIsLoaded = true;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleExpire(): void {
    this.captchaSuccess = false;
    this.captchaIsExpired = true;
    this.cdr.detectChanges();
  }
}

export class User {
  investor_Code: string
  investor_Name: string
  phone: string
  investor_Type: string
  onshore_Offshore: string
  paN_No: string
  id: number
  clientId: string
  emailIds: string
  token: string
}

export class OTPCriteria {
    value: string
    error:  number
    errorMsg: string
    code: number
    message: string
}