<div class="blue_box">
    <div class="header">
        <div class="container-fluid">
            <a class="logo"><img src="../../../assets/img/edelweiss_logo.png" alt=""></a>

            <ul class="top_right">
                <li class="user">
                    <ng-template #tipContent>
                        <div type="button" (click)="logout()">Logout</div>
                    </ng-template>
                    <div type="button" class="user_name" placement="bottom" 
                        [ngbTooltip]="tipContent" 
                        triggers="click"
                        [autoClose]="'outside'"><i class="fas fa-power-off"></i>
                    </div>
                </li> 
            </ul>

        </div>
    </div>    
    <div class="container-fluid">
    </div>    
</div>