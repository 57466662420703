import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalSettingsService } from './local-setting.service';

@Injectable()
export class AuthenticationService {

    constructor(
        private http: HttpClient,
        private localSettingsService: LocalSettingsService
    ) { }

    cachedRequests: Array<HttpRequest<any>> = [];
    baseURL: string = "https://swift.edelweissalternatives.com/"
    // baseURL: string = "https://www.mykshitij.com/PDGenie_UAT_API/"
    // baseURL: string=""

    getToken(){
        return !!this.localSettingsService.getTokenFromStorage()
    }

    // getUser(){
    //     return localStorage.getItem("SessionUser");
    // }

    public isAuthenticated(): boolean {
        // get the token
        const token = this.getToken();
        // return a boolean reflecting 
        // whether or not the token is expired
        return token == null ? false: true;
    }

    public collectFailedRequest(request): void {
        this.cachedRequests.push(request);
    }

    public retryFailedRequests(): void {
        // retry the requests. this method can
        // be called after the token is refreshed
    }

}
