import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class LoginService {
    
    constructor(
        private http: HttpClient,
        private authenticationService: AuthenticationService
    ) { }

    getOTP(userId: string) {
        return this.http.post<OTPCriteria>(this.authenticationService.baseURL+'api/authentication/login',{
            "userId": userId
        });
    }

    verifyOTP(emailId: string, otp: string) {
        return this.http.post<any>(this.authenticationService.baseURL+'api/authentication/validateotp',{
            "EmailId": emailId,
            "OTP": otp
        });
    }

    getLoggedInUserDetails(emailId: string){
        return this.http.post<any>(this.authenticationService.baseURL+'api/investor/details',{
            "emailId": emailId
            // pan:"AACTA8629B"
        });
    }

    getPan(emailId: string){
        return this.http.post<any>(this.authenticationService.baseURL+'api/investor/getPan',{
            "emailId": emailId
        })

    }
}

export class OTPCriteria {
    value: string
    error:  number
    errorMsg: string
    code: number
    message: string
}
