import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';
import { LocalSettingsService } from './local-setting.service';
 
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
      public auth: AuthenticationService,
      private localSettings: LocalSettingsService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.localSettings.getTokenFromStorage()
    request = request.clone({
      setHeaders: {
        Authorization: `${token}`
      }
    });
    return next.handle(request);
  }
}